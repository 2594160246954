import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Login from "../pages/authentication/Login";
import Subscription from "../pages/Subscription";
import { getData } from "../api/api";
import Layout2 from "../components/WithoutSidebar";
const ProtectedRoute = () => {
  const [token, setToken] = useState(null);
  const [Subscribe, setSubscribe] = useState(null);
  const [User, setUser] = useState([]);
  const location = useLocation();
  // Check for token
  const storedData = JSON.parse(
    localStorage.getItem("userData") || sessionStorage.getItem("userData")
  );

  /************************************************************/
  const fetchviewUser = async (token) => {
    try {
      // setToken(localStorage.getItem("token"))
      const res = await getData(`viewUser`, token);
      setSubscribe(res.data.data.isSubscriber);
      setUser(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  /************************************************************ */
  useEffect(() => {
    fetchviewUser(storedData.token);
  }, []);
  console.log("console path name" + location.pathname);
  // If there's no token, redirect to the login page, else render children
  if (storedData) {
    if (!Subscribe) {
      // console.log(storedData.user, "meradata");
      //sying undefined
      return (
        <Layout2>
          <Subscription />
        </Layout2>
      );
    } else {
      const blockedTile = new Set([
        "/community",
        "/Mealplan",
        "/Recipes",
        "/NutritionBootcamp",
        "/subscription",
      ]);
      if (blockedTile.has(location.pathname)) {
        let key;
        if (location.pathname == "/subscription") {
          return <Navigate to="/" />;
        }
        if (location.pathname == "/community") {
          key = "community";
        }
        if (
          location.pathname == "/Mealplan" ||
          location.pathname == "/Recipes"
        ) {
          key = "mealPlanandReceipes";
        }
        if (location.pathname == "/NutritionBootcamp") {
          key = "nutrition";
        }
        if (!User.access[key]) {
          return <Navigate to="/noaccess" />;
        } else {
          return <Outlet />;
        }
      } else {
        return <Outlet />;
      }
    }
  } else {
    return <Navigate to="/" />;
  }
};

export default ProtectedRoute;
