import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table } from "reactstrap";
import { Link } from "react-router-dom";
import "../../assets/css/style.css";
import topcurve from "../../assets/image/topcurve.png";
import check from "../../assets/image/check.svg";
import { getData, postData } from "../../api/api";
import PaymentModel from "../PaymentModel";
const Subscription = () => {
  const [modal, setModal] = useState(false);
  const [token, setToken] = useState(null);
  let [plans, setPlans] = useState([]);
  let [priceid, setPriceId] = useState(null);

  let [btnclass, setBtnclass] = useState([
    "dark",
    "blue",
    "green",
    "dark-green",
  ]);

  let [tdclass, setTdclass] = useState([
    "ltgrey",
    "ltblue",
    "ltgreen",
    "ltdgreen",
  ]);

  useEffect(() => {
    const fetchtoken = async () => {
      const storedData =
        (await localStorage.getItem("userData")) ||
        sessionStorage.getItem("userData");
      // Parse the JSON string back to an object
      const parsedData = JSON.parse(storedData);
      if (parsedData.token) {
        setToken(parsedData.token);
      }
    };
    fetchtoken();
  }, []);

  useEffect(() => {
    fetchData();
  }, [token]);

  const fetchData = async () => {
    try {
      // setToken(localStorage.getItem("token"))
      const res = await getData(`getProduct`, token);
      console.log(res.data.data, "token12345", token);
      setToken(localStorage.getItem("token"));
      setPlans(res.data.data);

      console.log("res.data.totalCount", res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const myToggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (priceid) {
      // Apply any side effects related to priceId here
      console.log("Price ID set:", priceid);

      // Optional: Cleanup function
      return () => {
        console.log("Cleaning up...");
      };
    }
  }, [priceid]);

  const letSubscribe = async (pid) => {
    console.log(pid);
    setPriceId(pid);
    // alert(priceid);
    setModal(true);
  };

  return (
    <>
      <div className="subsriptionouter">
        <Container fluid>
          <Row>
            <Col md="12" xl="5" className="p-0 d-none d-xl-block">
              <div className="loginlogo_div"></div>
            </Col>
            <Col className="loginfield_bg  position-relative" md="12" xl="7">
              <img src={topcurve} className="img-fluid topcurve" alt="" />
              <div className="subsriptionmain w-100 p-3">
                <h2 className="head-subs text-center">
                  B2B2C Subscription Model
                </h2>
                <div className="table-responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th width="20%"></th>
                        {plans?.length > 0 &&
                          plans.map((plan, index) => (
                            <th
                              key={index}
                              width="20%"
                              className={`table${btnclass[index]}`}
                            >
                              {index === plans?.length - 1 ? (
                                <>
                                  Enterprise{" "}
                                  <span>Fee + ${plan?.amount}/m</span>
                                </>
                              ) : (
                                `$${plan?.amount}/m`
                              )}
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Group Member #</td>
                        {plans?.length > 0 &&
                          plans.map((plan, index) => (
                            <td
                              key={index}
                              className={`td${tdclass[index]} curve${tdclass[index]}`}
                            >
                              {plan?.groupMember}
                            </td>
                          ))}
                      </tr>
                      <tr>
                        <td>Ethnocentric Meal Plans & Receipes</td>
                        {plans?.length > 0 &&
                          plans.map((plan, index) => (
                            <td key={index} className={`td${tdclass[index]}`}>
                              {plan?.mealPlanandReceipes && (
                                <img
                                  className="loginlogoimg"
                                  src={check}
                                  alt="check"
                                />
                              )}
                            </td>
                          ))}
                      </tr>
                      <tr>
                        <td>Hands-On Tools</td>
                        {plans?.length > 0 &&
                          plans.map((plan, index) => (
                            <td key={index} className={`td${tdclass[index]}`}>
                              {plan?.handsOnTool && (
                                <img
                                  className="loginlogoimg"
                                  src={check}
                                  alt="check"
                                />
                              )}
                            </td>
                          ))}
                      </tr>
                      <tr>
                        <td>Nutrition</td>
                        {plans?.length > 0 &&
                          plans.map((plan, index) => (
                            <td key={index} className={`td${tdclass[index]}`}>
                              {plan?.nutrition && (
                                <img
                                  className="loginlogoimg"
                                  src={check}
                                  alt="check"
                                />
                              )}
                            </td>
                          ))}
                      </tr>
                      <tr>
                        <td>Plans for children</td>
                        {plans?.length > 0 &&
                          plans.map((plan, index) => (
                            <td key={index} className={`td${tdclass[index]}`}>
                              {plan?.planforchildren && (
                                <img
                                  className="loginlogoimg"
                                  src={check}
                                  alt="check"
                                />
                              )}
                            </td>
                          ))}
                      </tr>
                      <tr>
                        <td>Community Access</td>
                        {plans?.length > 0 &&
                          plans.map((plan, index) => (
                            <td key={index} className={`td${tdclass[index]}`}>
                              {plan?.community && (
                                <img
                                  className="loginlogoimg"
                                  src={check}
                                  alt="check"
                                />
                              )}
                            </td>
                          ))}
                      </tr>
                      <tr>
                        <td></td>
                        {plans?.length > 0 &&
                          plans.map((plan, index) => (
                            <td key={index}>
                              <Link
                                className={`btn btn-${btnclass[index]}`}
                                onClick={() => letSubscribe(plan?.priceId)}
                              >
                                Choose Plan
                              </Link>
                            </td>
                          ))}
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <PaymentModel
        modal={modal}
        toggle={myToggle}
        pid={priceid}
        token={token}
      />
    </>
  );
};

export default Subscription;
