import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import opentogglebar from "../../../assets/image/togglebar.svg";
import Home from "../../../assets/image/sidebarhome_icon.svg";
import Home2 from "../../../assets/image/sidebarhome_icon2.svg";
import Dashboard from "../../../assets/image/sidebardashboard_icon.svg";
import Dashboard2 from "../../../assets/image/sidebardashboard_icon2.svg";
import NutritionPlanner from "../../../assets/image/sidebarplanner_icon.svg";
import NutritionPlanner2 from "../../../assets/image/sidebarplanner_icon2.svg";
import NutritionBootcamp from "../../../assets/image/sidebarbootcamp_icon.svg";
import NutritionBootcamp2 from "../../../assets/image/sidebarbootcamp_icon2.svg";
import Library from "../../../assets/image/sidebarlibrary_icon.svg";
import Library2 from "../../../assets/image/sidebarlibrary_icon2.svg";
import Community from "../../../assets/image/sidebarcommunity_icon.svg";
import Community2 from "../../../assets/image/sidebarcommunity_icon2.svg";
import mealplan from "../../../assets/image/sidebarmealplan_icon.svg";
import mealplan2 from "../../../assets/image/sidebarmealplan_icon2.svg";
import LiveSupport from "../../../assets/image/sidebarsupport_icon.svg";
import LiveSupport2 from "../../../assets/image/sidebarsupport_icon2.svg";
import AccountSettings from "../../../assets/image/sidebarsetting_icon.svg";
import AccountSettings2 from "../../../assets/image/sidebarsetting_icon2.svg";
import logoimg from "../../../assets/image/logo.png";

const Sidebar = () => {
  const [openDropdowns, setOpenDropdowns] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleDropdown = (dropdownId) => {
    if (openDropdowns === dropdownId) {
      setOpenDropdowns(null); // Close the dropdown if it's already open
    } else {
      setOpenDropdowns(dropdownId); // Open the clicked dropdown
    }
  };

  const closeAllDropdowns = () => {
    setOpenDropdowns([]);
  };

  const handleOutsideClick = (event) => {
    if (openDropdowns !== null && !event.target.closest(".dropdown")) {
      closeAllDropdowns();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [openDropdowns]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  React.useEffect(() => {
    document.body.classList.toggle("no-scroll", sidebarOpen);
  }, [sidebarOpen]);
  const handleNavLinkClick = () => {
    setSidebarOpen(false);
    closeAllDropdowns();
    if (!sidebarOpen) {
      toggleSidebar();
    }
  };
  const dropdownData = [
    {
      id: "Home",
      title: "Home",
      sidebaricon: Home,
      sidebaricon2: Home2,
      link: "/Home",
      isDropdown: false,
    },
    {
      id: "Dashboard",
      title: "My Dashboard",
      sidebaricon: Dashboard,
      sidebaricon2: Dashboard2,
      link: "/Dashboard",
      isDropdown: false,
    },
    {
      id: "NutritionPlanner",
      title: "My Daily Journal",
      sidebaricon: NutritionPlanner,
      sidebaricon2: NutritionPlanner2,
      isDropdown: false,
      link: "/NutritionPlanner",
    },
    {
      id: "MealPlans",
      title: "My Meal Plans",
      sidebaricon: mealplan,
      sidebaricon2: mealplan2,
      isDropdown: false,
      link: "/Mealplan",
    },
    {
      id: "NutritionBootcamp",
      title: "My Nutrition Academy",
      sidebaricon: NutritionBootcamp,
      sidebaricon2: NutritionBootcamp2,
      isDropdown: false,
      link: "/NutritionBootcamp",
    },
    {
      id: "Community",
      title: "My Community",
      sidebaricon: Community,
      sidebaricon2: Community2,
      isDropdown: false,
      link: "/community",
    },
    {
      id: "Library",
      title: "My Library",
      sidebaricon: Library,
      sidebaricon2: Library2,
      isDropdown: true,
      links: [
        {
          id: "Videos",
          label: "Videos",
          link: "/Videos",
        },
        {
          id: "Recipes",
          label: "Recipes",
          link: "/Recipes",
        },
        {
          id: "Questionnaires",
          label: "Questionnaires",
          link: "/Questionnaire",
        },
      ],
    },

    {
      id: "LiveSupport",
      title: "Live Support",
      sidebaricon: LiveSupport,
      sidebaricon2: LiveSupport2,
      isDropdown: true,
      links: [
        {
          id: "ContactUs",
          label: "Contact Us",
          link: "/ContactUs",
        },
        {
          id: "TermsConditions",
          label: "Terms & Conditions",
          link: "/TermsConditions",
        },
        {
          id: "FAQs",
          label: "FAQs",
          link: "/FAQs",
        },
        // {
        //   id: "AboutApplication",
        //   label: "About Application",
        //   link: "/AboutApplication",
        // },
      ],
    },
    {
      id: "AccountSettings",
      title: "Account Settings",
      sidebaricon: AccountSettings,
      sidebaricon2: AccountSettings2,
      isDropdown: true,
      links: [
        {
          id: "Profile",
          label: "Profile",
          link: "/ProfileSetting",
        },
        {
          id: "password",
          label: "Change Password",
          link: "/ChangePassword",
        },
        {
          id: "General",
          label: "General",
          link: "javascript:void(0)",
        },
        {
          id: "managesubscriptions",
          label: "Manage Subscriptions",
          link: "/manage_subscriptions",
        },
        // {
        //   id: "Appearance",
        //   label: "Appearance",
        //   link: "javascript:void(0)",
        // },
        // {
        //   id: "Security",
        //   label: "Security",
        //   link: "javascript:void(0)",
        // },
        {
          id: "Notifications",
          label: "Notifications",
          link: "/Notifications",
        },
        {
          id: "Accessibility",
          label: "Accessibility",
          link: "javascript:void(0)",
        },
        // {
        //   id: "Billing",
        //   label: "Billing",
        //   link: "javascript:void(0)",
        // },
      ],
    },
  ];
  return (
    <>
      <button
        onClick={toggleSidebar}
        className="btnopen"
        style={{ display: "none" }}
      >
        <img src={opentogglebar} alt="" />
      </button>
      <button
        className={
          sidebarOpen ? "btnclose sidebarbg-open" : "btnclose sidebarbg-closed"
        }
        onClick={toggleSidebar}
        style={{ display: "none" }}
      ></button>
      <div
        className={
          sidebarOpen ? "sidebar sidebar-open" : "sidebar sidebar-closed"
        }
      >
        <div className="sidebarlogo_div">
          <Link to="/Dashboard">
            <img src={logoimg} alt="" />
          </Link>
        </div>
        <ul>
          {dropdownData.map((dropdown) => (
            <li
              key={dropdown.id}
              className={`dropdown ${
                openDropdowns === dropdown.id ? "open" : ""
              }`}
            >
              {dropdown.isDropdown ? (
                <>
                  <button
                    className="dropdown-toggle"
                    onClick={() => toggleDropdown(dropdown.id)}
                  >
                    <img
                      className="sidebaricon"
                      src={dropdown.sidebaricon}
                      alt=""
                    />
                    <img
                      className="sidebaractiveicon"
                      src={dropdown.sidebaricon2}
                      alt=""
                    />
                    {dropdown.title}
                  </button>
                  {openDropdowns === dropdown.id && (
                    <ul className="dropdown-links dropdown-menu">
                      {dropdown.links.map((link) => (
                        <li key={link.id}>
                          <NavLink
                            to={link.link}
                            onClick={toggleSidebar}
                            className="dropdown-link"
                          >
                            {link.label}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              ) : (
                <NavLink
                  to={dropdown.link}
                  onClick={() => handleNavLinkClick(dropdown.id)}
                >
                  <img
                    className="sidebaricon"
                    src={dropdown.sidebaricon}
                    alt=""
                  />
                  <img
                    className="sidebaractiveicon"
                    src={dropdown.sidebaricon2}
                    alt=""
                  />
                  {dropdown.title}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
