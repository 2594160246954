import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Collapse,
  Navbar,
  Input,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
} from "reactstrap";
import ReactCountryFlag from "react-country-flag";
import { User, LogOut, Search, Bell } from "react-feather";
import userimg from "../../../assets/image/user1.jpg";
import logoimg from "../../../assets/image/logo.png";
import { getData, postData } from "../../../api/api";
import { toast } from "react-toastify";
import { LocalStorage } from "../../Helper/NextSevenDates";
const Header = (args) => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [userPic, setuserPic] = useState(
    "http://www.newerahospitalnagpur.com/admin/uploads/donors/4549_ad.png"
  );
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchtoken = async () => {
      const storedData = LocalStorage.get("userData");
      // (await LocalStorage.getItem("userData")) ||
      // sessionStorage.getItem("userData");
      // Parse the JSON string back to an object
      const parsedData = JSON.parse(storedData);

      if (parsedData.token) {
        setToken(parsedData.token);
        await getData("viewUser", parsedData.token)
          .then((res) => {
            if (res.data.status) {
              // setuserPic(res.data?.data?.profilePic);
              //  navigate("/ProfileSetting");
              if (res.data.data.profilePic) {
                setuserPic(
                  "https://askportia.s3.amazonaws.com/" +
                    res.data.data.profilePic
                );
              } else {
                setuserPic(
                  "http://www.newerahospitalnagpur.com/admin/uploads/donors/4549_ad.png"
                );
              }
            }
          })
          .catch((err) => {
            alert("some error accure");
          });
      } else {
        alert("some error accure2");
      }
    };
    fetchtoken();
  }, []);
  useEffect(() => {
    fetchData();
  }, [token]);
  const fetchData = async () => {
    try {
      // setToken(localStorage.getItem("token"))
      const res = await getData(`getUserNotification`, token);
      console.log("unreadCount", res.data);
      setUnreadCount(res.data.unreadCount);
    } catch (error) {
      console.log(error);
    }
  };
  const handleNotificationClick = async (e) => {
    e.preventDefault();
    // duumyFunc()
    await postData({}, "userReadNotification", token)
      .then((res) => {
        //console.log(res);
        if (res.data.status) {
          // toast(res.data.message);
          fetchData();
          navigate("/notifications");
        } else {
          // toast(res.data.message);
        }
      })
      .catch((err) => {
        alert(`Opration Failed from server side ${err}`);
      });
  };

  const logout = async () => {
    await postData({ token: token }, "logoutfromdevice", token)
      .then((res) => {
        if (res.data.status) {
          localStorage.removeItem("userData");
          sessionStorage.removeItem("userData");
          window.location.href = "/";
        }
      })
      .catch((err) => {
        alert("some error accure");
      });
  };
  return (
    <div className="header">
      <Navbar {...args} expand="md">
        <Link
          className="logoheader"
          to="/dashboard"
          style={{ display: "none" }}
        >
          <img src={logoimg} alt="" />
        </Link>
        <Collapse navbar>
          <Nav className="ms-auto align-items-center" navbar>
            {/*<div className="searchweb_div">
              <Search className="ficon" />
               <Input
                type="text"
                placeholder="Search for recipes, videos or plans..."
                className="search_input"
              />
            </div>*/}
            <div className="notification-bell-container">
              <Link onClick={handleNotificationClick}>
                <Bell size={21} />
                {unreadCount > 0 && (
                  <span className="notification-badge">{unreadCount}</span>
                )}
              </Link>
            </div>
            <UncontrolledDropdown
              nav
              inNavbar
              className="dropdown-language nav-item"
            >
              <DropdownToggle nav>
                <ReactCountryFlag
                  className="country-flag"
                  countryCode="us"
                  svg
                />
                <span className="ms-1">English</span>
              </DropdownToggle>
              <DropdownMenu end style={{ right: 0, position: "absolute" }}>
                <DropdownItem>
                  <Link>
                    {" "}
                    <ReactCountryFlag
                      className="country-flag"
                      countryCode="us"
                      svg
                    />
                    <span className="ms-1">English</span>
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link>
                    <ReactCountryFlag
                      className="country-flag"
                      countryCode="es"
                      svg
                    />
                    <span className="ms-1">Spanish</span>
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar className="headerdropdown_menu">
              <DropdownToggle nav>
                <div className="headeruserimg">
                  <img src={userPic} alt="" />
                </div>
              </DropdownToggle>
              <DropdownMenu end style={{ right: 0, position: "absolute" }}>
                <DropdownItem>
                  <Link to="/ProfileSetting">
                    <User size={18} /> Profile Setting
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    onClick={() => {
                      logout();
                    }}
                    to="#"
                  >
                    <LogOut size={18} /> Logout
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
