import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import PostFormModal from "./components/PostFormModal";
import PostList from "./components/PostList";
import PostCommentsPage from "./components/PostCommentsPage";
import UserPage from "./components/UserPage";
import SubPage from "./components/SubPage";
import TopSubsPanel from "./components/TopSubsPanel";
import SearchResults from "./components/SearchResults";
import NotFoundPage from "./components/NotFoundPage";

import { Container } from "@material-ui/core/";
import { useMainPaperStyles } from "./styles/muiStyles";

import Login from "./pages/authentication/Login";
import Subscription from "./pages/Subscription";
import NoAccess from "./pages/NoAccess";
import Signup from "./pages/authentication/Signup";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import ResetPassword from "./pages/authentication/ResetPassword";
import ProfileSetting from "./pages/ProfileSetting";
import ManageBilling from "./pages/ManageBilling";
import Subscriptions from "./pages/ManagrSubscriptions";
import Home from "./pages/Home";
import Mealplan from "./pages/Mealplan";
import Weightloss from "./pages/Mealplan/Weightloss";
import StaticMeal from "./pages/Mealplan/StaticMeal";

import Videos from "./pages/Videos";
import Questionnaire from "./pages/Questionnaire";
import Recipes from "./pages/Recipes";
import PopupModel from "./pages/PopupModel";
import NutritionBootcamp from "./pages/NutritionBootcamp";
import CourseDetail from "./pages/CourseDetail";
import NutritionPlanner from "./pages/NutritionPlanner";
import ContactUs from "./pages/LiveSupport/ContactUs";
import TermsConditions from "./pages/LiveSupport/TermsConditions";
import FAQs from "./pages/LiveSupport/FAQs";
import AboutApplication from "./pages/LiveSupport/AboutApplication";
import NewQuestion from "./pages/newquestions/QuestionAnswer";
import ChangePassword from "./pages/ChangePassword/changepassword";
import VideoDetails from "./pages/Videos/VideoDetails";
import ViewDiary from "./pages/NutritionPlanner/ViewDiary";
import Tabs from "./pages/Tabs/DietitianPrep";
import FoodDiary from "./pages/Tabs/FoodDiary";
import SmartGoals from "./pages/Tabs/SmartGoals";
import Sections from "./pages/Tabs/Sections";
import FoodPrefrence from "./pages/Tabs/FoodPrefrence";
import LifeStyle from "./pages/Tabs/LifeStyle";

import Notifications from "./pages/Notifications";
import AddDiaryEntry from "./pages/NutritionPlanner/AddDiaryEntry";
import Dashboard from "./pages/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import Layout from "./components/Layout";
import Layout2 from "./components/WithoutSidebar";
import { Sentry } from "react-activity";
import "react-activity/dist/library.css";
import RecipeDetails from "./pages/Recipes/RecipeDetails";
import { getByAltText } from "@testing-library/react";
import { useDispatch } from "react-redux";
import { setUser } from "./reducers/userReducer";
import AddPostModal from "./components/PostFormModal";
import ProtectedRoute from "./components/ProtectedRoute ";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { onMessageListener, requestPermission } from "./firebase/firebase";

function App() {
  const classes = useMainPaperStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const storedData =
    localStorage.getItem("userData") || sessionStorage.getItem("userData");
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    dispatch(setUser());
  }, []);
  // useEffect(() => {
  //   console.log("useeffect called");
  //   requestPermission();
  //   // onMessageListener();
  //   console.log("useeffect ended");
  // }, []);
  return (
    <>
      {/* {loading ? (
        <div className="loaderdiv">
          <Sentry color="#87D234" size={40} speed={1} animating={true} />
        </div>
      ) : ( */}
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              storedData ? (
                <Layout>
                  <Home />
                </Layout>
              ) : (
                <Login />
              )
            }
          />

          <Route path="/Signup" element={<Signup />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route
            path="/ResetPassword/:email/:numericValue"
            element={<ResetPassword />}
          />

          <Route element={<ProtectedRoute />}>
            <Route
              path="/noaccess"
              element={
                <Layout>
                  <NoAccess />
                </Layout>
              }
            />
            <Route
              path="/subscription"
              element={
                <Layout2>
                  <Subscription />
                </Layout2>
              }
            />
            <Route path="/noaccess" element={<NoAccess />} />
            <Route
              path="/ProfileSetting"
              element={
                <Layout>
                  <ProfileSetting />{" "}
                </Layout>
              }
            />
            <Route
              path="/manage_subscriptions"
              element={
                <Layout>
                  <Subscriptions />
                </Layout>
              }
            />
            <Route
              path="/managebilling"
              element={
                <Layout>
                  <ManageBilling />
                </Layout>
              }
            />
            <Route
              path="/model"
              element={
                <Layout>
                  <PopupModel></PopupModel>
                </Layout>
              }
            />
            <Route
              path="/ProfileSetting"
              element={
                <Layout>
                  <ProfileSetting />
                </Layout>
              }
            />
            <Route
              path="/Mealplan"
              element={
                <Layout>
                  <Mealplan />
                </Layout>
              }
            />
            <Route
              path="/ContactUs"
              element={
                <Layout>
                  <ContactUs />
                </Layout>
              }
            />
            <Route
              path="/TermsConditions"
              element={
                <Layout>
                  <TermsConditions />
                </Layout>
              }
            />
            <Route
              path="/FAQs"
              element={
                <Layout>
                  <FAQs />
                </Layout>
              }
            />
            <Route
              path="/AboutApplication"
              element={
                <Layout>
                  <AboutApplication />
                </Layout>
              }
            />
            <Route
              path="/Notifications"
              element={
                <Layout>
                  <Notifications />
                </Layout>
              }
            />
            <Route
              path="/NewQuestion"
              element={
                <Layout>
                  <NewQuestion />
                </Layout>
              }
            />
            <Route
              path="/Videos"
              element={
                <Layout>
                  <Videos />
                </Layout>
              }
            />
            <Route
              path="/Questionnaire"
              element={
                <Layout>
                  <Sections />
                </Layout>
              }
            />
            <Route
              path="/FoodDiary"
              element={
                <Layout>
                  <FoodDiary />
                </Layout>
              }
            />
            <Route
              path="/SmartGoals"
              element={
                <Layout>
                  <SmartGoals />
                </Layout>
              }
            />
            <Route
              path="/Recipes"
              element={
                <Layout>
                  <Recipes />
                </Layout>
              }
            />
            <Route
              path="/VideoDetails/:id"
              element={
                <Layout>
                  <VideoDetails />
                </Layout>
              }
            />
            <Route
              path="/Weightloss/:id"
              element={
                <Layout>
                  <Weightloss />
                </Layout>
              }
            />
            <Route
              path="/StaticMeal/:link/:title"
              element={
                <Layout>
                  <StaticMeal />
                </Layout>
              }
            />
            <Route
              path="/RecipeDetails/:id"
              element={
                <Layout>
                  <RecipeDetails />
                </Layout>
              }
            />
            <Route
              path="/ViewDiary/:date"
              element={
                <Layout>
                  <ViewDiary />
                </Layout>
              }
            />
            <Route
              path="/DietitianPrep"
              element={
                <Layout>
                  <Tabs />
                </Layout>
              }
            />
            <Route
              path="/LifeStyle"
              element={
                <Layout>
                  <LifeStyle />
                </Layout>
              }
            />
            <Route
              path="/Sections"
              element={
                <Layout>
                  <Sections />
                </Layout>
              }
            />
            <Route
              path="/NewTabs"
              element={
                <Layout>
                  <Questionnaire />
                </Layout>
              }
            />
            <Route
              path="/NutritionBootcamp"
              element={
                <Layout>
                  <NutritionBootcamp />
                </Layout>
              }
            />
            <Route
              path="/course-detail"
              element={
                <Layout>
                  <CourseDetail />
                </Layout>
              }
            />
            <Route
              path="/NutritionPlanner"
              element={
                <Layout>
                  <NutritionPlanner />
                </Layout>
              }
            />
            <Route
              path="/AddDiaryEntry"
              element={
                <Layout>
                  <AddDiaryEntry />
                </Layout>
              }
            />
            <Route
              path="/FoodPrefrence"
              element={
                <Layout>
                  <FoodPrefrence />
                </Layout>
              }
            />
            <Route
              path="/Dashboard"
              element={
                <Layout>
                  <Dashboard />
                </Layout>
              }
            />
            <Route
              path="/Home"
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />
            <Route
              path="/community"
              element={
                <Layout>
                  {/* <Container disableGutters className={classes.homepage}> */}
                  <PostList />
                  {/* </Container> */}
                </Layout>
              }
            />
            <Route
              path="/comments/:id"
              element={
                <Layout>
                  <PostCommentsPage />
                </Layout>
              }
            />
            <Route
              path="/u/:username"
              element={
                <Layout>
                  <UserPage />
                </Layout>
              }
            />
            <Route
              path="/r/:sub"
              element={
                <Layout>
                  <SubPage />
                </Layout>
              }
            />
            <Route
              path="/search/:query"
              element={
                <Layout>
                  <SearchResults />
                </Layout>
              }
            />
            <Route
              path="/ChangePassword"
              element={
                <Layout>
                  <ChangePassword />{" "}
                </Layout>
              }
            />
            <Route
              path="*"
              element={
                <Layout>
                  <NotFoundPage />
                </Layout>
              }
            />
          </Route>
          <Route
            element={
              <Layout>
                <Outlet />
              </Layout>
            }
          ></Route>
        </Routes>
      </Router>
      {/* )} */}
    </>
  );
}

export default App;
